<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <form class="status_color_form">
        <ul>
          <li>
            <div>
              <input
                id="statusThBgColor"
                type="color"
                v-model="status_background_pick"
              />
            </div>
            <label for="statusThBgColor">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="statusTime" type="color" v-model="status_time" />
            </div>
            <label for="statusTime">조회시간 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBdColor"
                type="color"
                v-model="status_border_pick"
              />
            </div>
            <label for="statusThBdColor">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBdiColor"
                type="color"
                v-model="status_border_inner"
              />
            </div>
            <label for="statusThBdiColor">테이블 내부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtColor"
                type="color"
                v-model="status_background_temp"
              />
            </div>
            <label for="statusThCrtColor">테이블 제목 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThCrtFont"
                type="color"
                v-model="status_color_temp"
              />
            </div>
            <label for="statusThCrtFont"> 테이블 제목 글자</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="statusThTableColor"
                type="color"
                v-model="status_background_table"
              />
            </div>
            <label for="statusThTableColor">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input
                id="statusThDetailColor"
                type="color"
                v-model="status_color_detail"
              />
            </div>
            <label for="statusThDetailColor">테이블 내용 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBtnColor"
                type="color"
                v-model="status_background_button"
              />
            </div>
            <label for="statusThBtnColor">전체화면&재조회 버튼 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThBtnFont"
                type="color"
                v-model="status_color_button"
              />
            </div>
            <label for="statusThBtnFont">전체화면&재조회 버튼 글자</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideColor"
                type="color"
                v-model="status_background_slide_btn"
              />
            </div>
            <label for="statusThSlideColor">이전/다음 버튼 배경</label>
          </li>
          <li>
            <div>
              <input
                id="statusThSlideIcon"
                type="color"
                v-model="status_color_slide_btn"
              />
            </div>
            <label for="statusThSlideIcon">이전/다음 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button class="btn_sub2" @click.prevent="showModal($event)">
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormColor">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeColor()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //실시간 온습도 현황 Color
      status_time: '#004a89',
      status_background_pick: '#fbfbfb',
      status_border_pick: '#fbfbfb',
      status_border_inner: '#ffffff',
      status_background_temp: '#29674e',
      status_color_temp: '#ffffff',
      status_background_table: '#36916c',
      status_color_detail: '#ffffff',
      status_background_button: '#eaeaea',
      status_color_button: '#000000',
      status_background_slide_btn: '#ffffff',
      status_color_slide_btn: '#000000',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  mounted() {
    this.status_time = localStorage.getItem('statusTime');
    this.status_background_pick = localStorage.getItem('statusArticleColor');
    this.status_border_pick = localStorage.getItem('statusMesborTbl');
    this.status_border_inner = localStorage.getItem('statusMesboriTbl');
    this.status_background_temp = localStorage.getItem('statusTempbgColor');
    this.status_color_temp = localStorage.getItem('statusTempFont');

    this.status_background_table = localStorage.getItem('statusTableColor');
    this.status_color_detail = localStorage.getItem('statusDetailFont');
    this.status_background_button = localStorage.getItem('statusBtnColor');
    this.status_color_button = localStorage.getItem('statusBtnFontColor');
    this.status_background_slide_btn = localStorage.getItem(
      'statusSlideBtnColor',
    );
    this.status_color_slide_btn = localStorage.getItem('statusSlideBtniColor');
  },

  methods: {
    showModal() {
      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    submitFormColor() {
      localStorage.setItem('statusTime', this.status_time);
      localStorage.setItem('statusArticleColor', this.status_background_pick);
      localStorage.setItem('statusMesborTbl', this.status_border_pick);
      localStorage.setItem('statusMesboriTbl', this.status_border_inner);
      localStorage.setItem('statusTempbgColor', this.status_background_temp);
      localStorage.setItem('statusTempFont', this.status_color_temp);
      localStorage.setItem('statusDivColor', this.status_background_division);
      localStorage.setItem('statusDivFont', this.status_color_division);
      localStorage.setItem('statusTableColor', this.status_background_table);
      localStorage.setItem('statusDetailFont', this.status_color_detail);
      localStorage.setItem(
        'statusDetailTempFont',
        this.status_color_detail_temp,
      );
      localStorage.setItem('statusBtnColor', this.status_background_button);
      localStorage.setItem('statusBtnFontColor', this.status_color_button);
      localStorage.setItem(
        'statusSlideBtnColor',
        this.status_background_slide_btn,
      );
      localStorage.setItem('statusSlideBtniColor', this.status_color_slide_btn);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeColor() {
      localStorage.setItem('statusTime', '#004a89'); // 현재시간
      localStorage.setItem('statusArticleColor', '#fbfbfb'); //배경
      localStorage.setItem('statusMesborTbl', '#fbfbfb'); //테두리외부
      localStorage.setItem('statusMesboriTbl', '#ffffff'); //테두리내부
      localStorage.setItem('statusTempbgColor', '#29674e'); //온습도현황 칼라
      localStorage.setItem('statusTempFont', '#ffffff'); //온습도현황 폰트
      localStorage.setItem('statusTableColor', '#36916c'); //테이블 칼라
      localStorage.setItem('statusDetailFont', '#ffffff'); //온습도폰트
      localStorage.setItem('statusBtnColor', '#eaeaea'); //버튼색
      localStorage.setItem('statusBtnFontColor', '#000000'); // 버튼폰트
      localStorage.setItem('statusSlideBtnColor', '#ffffff'); //슬라이드 칼라
      localStorage.setItem('statusSlideBtniColor', '#000000'); //슬라이드 폰트

      this.status_time = '#004a89';
      this.status_background_pick = '#fbfbfb';
      this.status_border_pick = '#fbfbfb';
      this.status_border_inner = '#ffffff';
      this.status_background_temp = '#29674e';
      this.status_color_temp = '#ffffff';
      this.status_background_table = '#36916c';
      this.status_color_detail = '#ffffff';
      this.status_background_button = '#eaeaea';
      this.status_color_button = '#000000';
      this.status_background_slide_btn = '#ffffff';
      this.status_color_slide_btn = '#000000';
      this.CloseModal();
    },
  },
  async created() {
    if (localStorage.getItem('statusTime') == undefined) {
      localStorage.setItem('statusTime', this.status_time);
    }
    if (localStorage.getItem('statusArticleColor') == undefined) {
      localStorage.setItem('statusArticleColor', this.status_background_pick);
    }
    if (localStorage.getItem('statusMesborTbl') == undefined) {
      localStorage.setItem('statusMesborTbl', this.status_border_pick);
    }
    if (localStorage.getItem('statusMesboriTbl') == undefined) {
      localStorage.setItem('statusMesboriTbl', this.status_border_inner);
    }
    if (localStorage.getItem('statusTempbgColor') == undefined) {
      localStorage.setItem('statusTempbgColor', this.status_background_temp);
    }
    if (localStorage.getItem('statusTempFont') == undefined) {
      localStorage.setItem('statusTempFont', this.status_color_temp);
    }
    if (localStorage.getItem('statusTableColor') == undefined) {
      localStorage.setItem('statusTableColor', this.status_background_table);
    }
    if (localStorage.getItem('statusDetailFont') == undefined) {
      localStorage.setItem('statusDetailFont', this.status_color_detail);
    }
    if (localStorage.getItem('statusBtnColor') == undefined) {
      localStorage.setItem('statusBtnColor', this.status_background_button);
    }
    if (localStorage.getItem('statusBtnFontColor') == undefined) {
      localStorage.setItem('statusBtnFontColor', this.status_color_button);
    }
    if (localStorage.getItem('statusSlideBtnColor') == undefined) {
      localStorage.setItem(
        'statusSlideBtnColor',
        this.status_background_slide_btn,
      );
    }
    if (localStorage.getItem('statusSlideBtniColor') == undefined) {
      localStorage.setItem('statusSlideBtniColor', this.status_color_slide_btn);
    }
  },
};
</script>

<style lang="scss" scoped></style>
