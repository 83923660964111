<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColor};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 :style="`color: ${statusTime};`">조회시간: {{ date }}</h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filteredProductStatus"
          :key="index"
        >
          <div
            class="auto_width"
            :style="`border: 2px solid ${statusMesborTbl}`"
          >
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th
                    colspan="8"
                    :style="
                      `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    <span>일일 생산량</span>
                  </th>
                </tr>
                <tr>
                  <th
                    :style="
                      `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    품목
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    납품처
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    품목(단위)
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    생산수량(ea)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(productStatus, index2) in items"
                  :key="productStatus.id"
                >
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 0; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{ productStatus.product_name }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{ productStatus.company_name }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{ productStatus.product_standard }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{
                      `${$makeComma(productStatus.quantity)}(${
                        findInfoFromId(
                          unitCodes,
                          findInfoFromId(product, productStatus.product_id)
                            .stock_unit_id,
                        ).name
                      })`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap" v-show="filteredProductStatus.length != 0">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent="autoPlay()"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';

export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTime: localStorage.getItem('statusTime'),
      statusArticleColor: localStorage.getItem('statusArticleColor'),
      statusMesborTbl: localStorage.getItem('statusMesborTbl'),
      statusMesboriTbl: localStorage.getItem('statusMesboriTbl'),
      statusTempbgColor: localStorage.getItem('statusTempbgColor'),
      statusTempFont: localStorage.getItem('statusTempFont'),
      statusDivColor: localStorage.getItem('statusDivColor'),
      statusDivFont: localStorage.getItem('statusDivFont'),
      statusTableColor: localStorage.getItem('statusTableColor'),
      statusDetailFont: localStorage.getItem('statusDetailFont'),
      statusDetailTempFont: localStorage.getItem('statusDetailTempFont'),
      statusBtnColor: localStorage.getItem('statusBtnColor'),
      statusBtnFontColor: localStorage.getItem('statusBtnFontColor'),
      statusSlideBtnColor: localStorage.getItem('statusSlideBtnColor'),
      statusSlideBtniColor: localStorage.getItem('statusSlideBtniColor'),
    };
  },
  computed: {
    ...mapGetters({
      productStatusList: 'getProductStatusListFromDailyOutput',
      product: 'getProduct',
      unitCodes: 'getUnitCodes',
      productInOutType: 'getProductInOutType',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredProductStatus() {
      if (this.productStatusList !== undefined) {
        const date = new Date();
        let filterToday = this.productStatusList.filter(
          x =>
            x.input_date == yyyymmdd(date) &&
            !this.findInfoFromId(this.product, x.product_id).oem_product_yn,
        );
        let filterInOutType = filterToday.filter(x =>
          this.findInfoFromId(
            this.productInOutType,
            x.product_in_out_type_id == 1,
          ),
        );
        let arr = [];
        if (this.fullScreen) {
          filterInOutType.forEach((element, index) => {
            if (index % 9 == 0) {
              arr.push(filterInOutType.slice(index, index + 9));
            }
          });
        } else {
          filterInOutType.forEach((element, index) => {
            if (index % 5 == 0) {
              arr.push(filterInOutType.slice(index, index + 5));
            }
          });
        }
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER(
        'FETCH_PRODUCT_STATUS_DAY_DAILY',
        '제품 재고',
      );
      this.date = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    this.FETCH('FETCH_UNIT', '단위');
    this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '자재 입출고 유형');
    if (this.productStatusList.length < 1) {
      this.FETCH('FETCH_PRODUCT_STATUS_DAY_DAILY', '제품 재고');
    }
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 3) {
        await this.FETCH_NO_SPINNER(
          'FETCH_PRODUCT_STATUS_DAY_DAILY',
          '제품 재고',
        );
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.date = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
